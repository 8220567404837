<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>ข้อมูลการเชื่อมต่อ Sandbox</b-card-title>
            </div>
            <h4
              v-if="isApproved"
              class="m-0"
            >
              <b-link @click="onClickEdit">
                แก้ไขข้อมูล
              </b-link>
              <EditRequestModal
                :id="requestDetail.id"
                :request-detail="requestDetail"
              />
            </h4>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-row class="mb-2">
              <b-col>
                <label class="text-secondary">สถานะ</label>
              </b-col>
              <!-- Pending = 0
                    Rejected = 1
                    Activated = 2 -->
              <b-col class="text-right">
                <strong
                  v-if="+requestDetail.status === 0"
                  class="text-warning"
                >รอการอนุมัติ
                </strong>
                <strong
                  v-else-if="+requestDetail.status === 1"
                  class="text-danger"
                >ไม่อนุมัติ
                  <b-link @click="onRegister">
                    (คลิกที่นี่เพื่อสมัครใหม่อีกครั้ง)
                  </b-link>
                </strong>
                <strong
                  v-else-if="+requestDetail.status === 2"
                  class="text-success"
                >อนุมัติ</strong>
                <!-- <strong
                  v-if="+requestDetail.status === 1"
                  class="text-danger"
                >ระงับการใช้งาน
                  <span class="text-white">
                    (กรุณาติดต่อผู้ดูแล)
                  </span>

                </strong> -->
              </b-col>
            </b-row>
            <b-form-group
              label="API Key"
              label-for="API Key"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="API Key"
                :value="requestDetail.apiKey || 'ยังไม่มีข้อมูล'"
                class="text-right"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="รูปแบบการเชื่อมต่อ"
              label-for="รูปแบบการเชื่อมต่อ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="รูปแบบการเชื่อมต่อ"
                :value="requestDetail.environment === 0 ?'Sandbox': requestDetail.environment === 1 ? 'Production' : requestDetail.environment"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Callback URL"
              label-for="Callback URL"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="Callback URL"
                :value="requestDetail.callbackUrl || ''"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Whitelist IP"
              label-for="Whitelist"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                v-for="ip in whitelist"
                :id="ip"
                :key="ip"
                :value="ip"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <h5 class="ml-2 text-weight-normal text-secondary">
      ข้อมูลด้านบนเป็นข้อมูลที่ใช้สำหรับเชื่อมต่อกับ Sandbox เท่านั้น
      คุณจำเป็นที่จะต้องทดสอบ Callback ตามที่เรากำหนดให้ผ่านทั้งหมด
      เพื่อขอสิทธิ์ในการเชื่อมต่อกับ Production
    </h5>
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      :variant="isApproved ? 'flat-primary' : 'flat-secondary'"
      :disabled="!isApproved"
    >
      <feather-icon
        icon="LogInIcon"
        class="mr-50"
      />
      <span class="align-middle">ทดสอบ Callback</span>
    </b-button><br>

    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-primary"
    >
      <feather-icon
        icon="LogInIcon"
        class="mr-50"
      />
      <span class="align-middle">เอกสารการเชื่อมต่อ API</span>
    </b-button><br>

    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-primary"
    >
      <feather-icon
        icon="LogInIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ 'Game Assetes & Material' }}</span>
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    EditRequestModal: () => import('./EditRequestModal.vue'),
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isGettingRequestDetail,
    }),
    ...mapGetters(['myRequests']),
    requestDetail() {
      return this.myRequests[0]
        ? this.myRequests[0]
        : { id: '', status: 0, environment: 0 }
    },
    isApproved() {
      const { status } = this.requestDetail
      return status === 2
    },
    requestId() {
      console.log(this.requestDetail)
      const { id } = this.requestDetail
      return id
    },
    whitelist() {
      const { whitelistIp } = this.requestDetail
      return whitelistIp?.split(',') || ['']
    },
  },
  watch: {},
  created() {
    this.fetchData()
    this.setData()
  },
  methods: {
    ...mapActions(['getRequestDetail', 'sendRequest']),
    fetchData() {
      if (this.requestId) {
        this.getRequestDetail(this.requestId)
      }
    },
    setData() {},
    onClickEdit() {
      this.$bvModal.show('edit-request-modal')
    },
    onRegister() {
      const {
        integrateType,
        environment,
        callbackUrl,
        whitelistIp,
      } = this.requestDetail

      this.sendRequest({
        integrateType,
        environment,
        callbackUrl,
        whitelistIp,
      })
    },
    onSubmit() {
      this.$refs.customerRequestForm.validate().then(async (success) => {
        if (success) {
          //
        }
      })
    },
  },
}
</script>
